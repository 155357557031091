var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container container_responsive" }, [
    _c("div", { staticClass: "page-wrapper wrapper-padding_lg article" }, [
      _c("h1", { staticClass: "text-center" }, [
        _vm._v("\n      Какие могут быть риски при покупке квартиры\n    ")
      ]),
      _vm._m(0),
      _c("p", { staticClass: "mark" }, [
        _vm._v(
          "\n      При покупке квартиры в сделках больше всего рискует сам покупатель.\n    "
        )
      ]),
      _c("p", [
        _vm._v(
          "\n      В основном такое получается из-за нехватки знаний в области недвижимости. Покупатели при\n      поиске в интернете натыкаются только на банальные советы по поводу проверки покупаемой\n      квартиры, которые вряд ли помогут, если нарваться на мошенников.\n    "
        )
      ]),
      _c("p", [
        _vm._v(
          "\n      При получении ипотеки, банки проверяют квартиры только на факт возможной успешной\n      перепродажи, в случае если клиент не оплачивает ипотеку. При этом банки проверку на\n      юридические риски недвижимости не проводят.\n    "
        )
      ]),
      _c("p", [
        _vm._v(
          "\n      Риэлторы занимаются проверкой квартиры поверхностно, потому что большинство риэлторов не\n      являются юристами. Агентства недвижимости редко проводят глубокую проверку, и еще реже\n      предоставляют отчеты на юридическую чистоту квартиры (либо делают это за большие деньги).\n    "
        )
      ]),
      _vm._m(1),
      _c("h3", [_vm._v("Рассмотрим самые важные и главные риски:")]),
      _vm._m(2),
      _c("h3", [_vm._v("Как избежать распространенных ошибок")]),
      _c(
        "p",
        [
          _vm._v(
            "\n      Если вы уже нашли квартиру и выходите на сделку, можете самостоятельно\n      "
          ),
          _c(
            "router-link",
            {
              staticClass: "btn-link router-link-active",
              attrs: { to: { name: "home" }, target: "_blank" }
            },
            [_vm._v("\n        заказать выписку ЕГРН\n      ")]
          ),
          _vm._v(
            "\n      по кадастровому номеру или адресу и сверить данные - кому принадлежит квартира, является ли\n      продавец собственником и другую информацию. Но стоит учесть, что в выписках ЕГРН находится\n      не вся информация о квартире и собственнике.\n    "
          )
        ],
        1
      ),
      _c("div", { staticClass: "img-block" }, [
        _c("img", {
          staticClass: "img-block__img-offset",
          attrs: { src: "/images/static/office.jpg" }
        }),
        _c("p", [
          _vm._v("\n        Для более полной картины вы можете\n        "),
          _c(
            "span",
            { staticClass: "btn-link", on: { click: _vm.scrollToForm } },
            [_vm._v("заказать отчет о рисках")]
          ),
          _vm._v(
            ". Проверка\n        квартиры и собственника проводится в базах Росреестр, ФССП, МВД, ИФНС, Суды и т.д. нашим\n        юридическим отделом с многолетним стажем в области недвижимости.\n      "
          )
        ])
      ]),
      _c("p", [
        _vm._v(
          "\n      На основе этой проверки создается Отчет о рисках. Данный отчет содержит полную информацию о\n      недвижимости и собственниках:\n    "
        )
      ]),
      _vm._m(3),
      _c(
        "div",
        { staticClass: "product-info-meta d-flex justify-content-between" },
        [
          _c("div", { staticClass: "product-info-meta__item" }, [
            _vm._m(4),
            _c("div", { staticClass: "product-info-meta__value" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm._f("currency")(_vm.product.product_price)) +
                  "\n        "
              )
            ])
          ]),
          _vm._m(5),
          _c("div", { staticClass: "product-info-meta__item" }, [
            _vm._m(6),
            _c("div", { staticClass: "product-info-meta__value" }, [
              _vm._v("\n          PDF документ "),
              _c(
                "span",
                {
                  staticClass: "btn-link small",
                  on: {
                    click: function($event) {
                      return _vm.showExample("risk")
                    }
                  }
                },
                [_vm._v("Образец")]
              )
            ])
          ])
        ]
      ),
      _c("div", { staticClass: "product-info-stamp" }, [
        _vm._m(7),
        _c("div", { staticClass: "product-info-stamp__icon" }, [
          _c("ul", { staticClass: "mt-0" }, [
            _c("li", [
              _vm._v("\n            Отчет ЕГРН о переходе прав\n            "),
              _c(
                "span",
                {
                  staticClass: "btn-link small",
                  on: {
                    click: function($event) {
                      return _vm.showExample("rightList")
                    }
                  }
                },
                [_vm._v("Образец")]
              )
            ]),
            _c("li", [
              _vm._v("\n            Отчет ЕГРН о объекте\n            "),
              _c(
                "span",
                {
                  staticClass: "btn-link small",
                  on: {
                    click: function($event) {
                      return _vm.showExample("egrn")
                    }
                  }
                },
                [_vm._v("Образец")]
              )
            ])
          ])
        ])
      ])
    ]),
    _c(
      "div",
      { ref: "form", staticClass: "page-wrapper wrapper-padding_sm mt-4" },
      [
        _c("h2", { staticClass: "form-title text-center" }, [
          _vm._v("\n      Закажите отчет о рисках перед покупкой\n    ")
        ]),
        _c(
          "app-form",
          {
            staticClass: "form",
            attrs: { model: _vm.model, rules: _vm.validationRules },
            on: { submit: _vm.onSubmit }
          },
          [
            _c("div", { staticClass: "row no-gutters" }, [
              _c(
                "div",
                { ref: "searchWrap", staticClass: "col col_input" },
                [
                  _c(
                    "app-form-group",
                    { attrs: { "model-name": "query" } },
                    [
                      _c("app-dadata", {
                        attrs: {
                          placeholder: "Укажите кадастровый номер или адрес"
                        },
                        on: { focus: _vm.onFocus },
                        model: {
                          value: _vm.model.query,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "query", $$v)
                          },
                          expression: "model.query"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "col col_btn" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "app-button",
                      {
                        staticClass: "text-nowrap",
                        attrs: { type: "submit", size: "lg" }
                      },
                      [_vm._v("\n              найти объект\n            ")]
                    )
                  ],
                  1
                )
              ])
            ])
          ]
        ),
        _c("div", { staticClass: "startpage-steps" }, [
          _vm._m(8),
          _vm._m(9),
          _c("div", { staticClass: "startpage-steps__item" }, [
            _c("img", {
              staticClass: "startpage-steps__icon",
              attrs: { src: "/images/step-icons/approval.svg" }
            }),
            _c("div", { staticClass: "startpage-steps__text" }, [
              _vm._v("\n          Закажите отчет о рисках\n          "),
              _c(
                "span",
                {
                  staticClass: "btn-link",
                  on: {
                    click: function($event) {
                      return _vm.showExample("risk")
                    }
                  }
                },
                [_vm._v("Образец")]
              )
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-block" }, [
      _c("p", [
        _vm._v(
          "\n        Покупка квартиры на вторичном рынке содержит в себе много плюсов - дом построен и сдан,\n        инфраструктура развита, район обжит. Но рисков при покупке вторичной недвижимости даже\n        больше, чем в случае с новостройками.\n      "
        )
      ]),
      _c("img", { attrs: { src: "/images/static/building.png" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-block" }, [
      _c("img", {
        staticClass: "img-block__img-sm",
        attrs: { src: "/images/static/bandit.svg" }
      }),
      _c("p", [
        _vm._v("\n        Главная проблема -\n        "),
        _c("mark", { staticClass: "red" }, [
          _vm._v("покупатель теряет свои деньги")
        ]),
        _vm._v("\n        и\n        "),
        _c("mark", { staticClass: "red" }, [_vm._v("не получает квартиру")]),
        _vm._v(
          ". Покупателю приходится подавать в суд на\n        продавца, у которого, скорее всего, уже нет денег для возврата по договору купли-продажи.\n      "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "list" }, [
      _c("li", [
        _c("b", { staticClass: "d-block" }, [
          _vm._v("Ограничения на регистрационные действия")
        ]),
        _vm._v(
          "\n        Установление запрета на совершение регистрационных действий с недвижимостью препятствует\n        совершению сделок, направленных на отчуждение имущества в любой форме, в том числе путем\n        продажи.\n      "
        )
      ]),
      _c("li", [
        _c("b", { staticClass: "d-block" }, [_vm._v("Нахождение в залоге")]),
        _vm._v(
          "\n        Если квартира находится в залоге, то новый владелец может ее просто потерять, так как если\n        продавец не снимет обременение, то банк или другая организация, у которой недвижимость в\n        залоге, может изъять и продать ее.\n      "
        )
      ]),
      _c("li", [
        _c("b", { staticClass: "d-block" }, [_vm._v("Арест")]),
        _vm._v(
          "\n        Если продавец продает квартиру находящуюся в аресте, то, скорее всего, сделка\n        купли-продажи может затянуться или вообще сорваться.\n      "
        )
      ]),
      _c("li", [
        _c("b", { staticClass: "d-block" }, [
          _vm._v("Проверка продавца на дееспособность")
        ]),
        _vm._v(
          "\n        После совершения сделки купли-продажи инициатором ее расторжения выступает продавец, и, на\n        основе документов о его недееспособности, сделка признается недействительной, квартира\n        отходит обратно продавцу, а деньги можно вернуть только через суд.\n      "
        )
      ]),
      _c("li", [
        _c("b", { staticClass: "d-block" }, [
          _vm._v("История по правообладателям")
        ]),
        _vm._v(
          "\n        Если объект продавали несколько раз за последние 3 года - задумайтесь. Возможно после\n        сделки “всплывут” наследники на квартиру или третьи лица, оспаривающие сделку.\n      "
        )
      ]),
      _c("li", [
        _c("b", { staticClass: "d-block" }, [
          _vm._v("Махинации продавца на получение задатков")
        ]),
        _vm._v(
          "\n        Мошенники используют разнообразные схемы для обмана покупателей, чтобы уберечься от этого,\n        нужен опытный юрист в области недвижимости.\n      "
        )
      ]),
      _c("li", [
        _c("b", { staticClass: "d-block" }, [_vm._v("Обременение ипотекой")]),
        _vm._v(
          "\n        Если обременение большое, то сделку провести тяжело.\n      "
        )
      ]),
      _c("li", [
        _c("b", { staticClass: "d-block" }, [_vm._v("Банкротство продавца")]),
        _vm._v(
          "\n        Если продавец банкрот, то он не имеет права продавать квартиру.\n      "
        )
      ]),
      _c("li", [
        _c("b", { staticClass: "d-block" }, [_vm._v("Судебные споры")]),
        _vm._v(
          "\n        Может повлиять на продолжительность процесса сделки купли-продажи.\n      "
        )
      ]),
      _c("li", [
        _c("b", { staticClass: "d-block" }, [
          _vm._v("Подлинность правоустанавливающих документов на квартиру")
        ]),
        _vm._v(
          "\n        О сделке не может быть и речи, если документы не подлинные или не совпадают при сверке с\n        официальными данными из Росреестра.\n      "
        )
      ]),
      _c("li", [
        _c("b", { staticClass: "d-block" }, [_vm._v("Массовая регистрация")])
      ]),
      _c("li", [
        _c("b", { staticClass: "d-block" }, [
          _vm._v("Судебные процессы по объекту")
        ])
      ]),
      _c("li", [
        _c("b", { staticClass: "d-block" }, [_vm._v("В списке на снос")])
      ]),
      _c("li", [
        _c("b", { staticClass: "d-block" }, [
          _vm._v("Проверка несовершеннолетних")
        ])
      ]),
      _c("li", [
        _c("b", { staticClass: "d-block" }, [
          _vm._v("Незаконное строительство")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "list-cols" }, [
      _c("li", [_vm._v("ФИО всех собственников с 1998 года")]),
      _c("li", [_vm._v("Серию и номер свидетельства о регистрации")]),
      _c("li", [_vm._v("Даты перехода прав")]),
      _c("li", [_vm._v("Обременения (арест/залог)")]),
      _c("li", [_vm._v("Кадастровую стоимость")]),
      _c("li", [_vm._v("Задолженность собственников")]),
      _c("li", [_vm._v("Проверка действительности паспорта")]),
      _c("li", [_vm._v("Банкротство")]),
      _c("li", [_vm._v("Судебные разбирательства")]),
      _c("li", [_vm._v("В списке на снос")]),
      _c("li", [_vm._v("Массовая регистрация")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "product-info-meta__label" }, [
      _c("i", { staticClass: "far fa-credit-card mr-2" }),
      _c("span", [_vm._v("Стоимость:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "product-info-meta__item" }, [
      _c("div", { staticClass: "product-info-meta__label" }, [
        _c("i", { staticClass: "fas fa-hourglass-start mr-2" }),
        _c("span", [_vm._v("Срок исполнения:")])
      ]),
      _c("div", { staticClass: "product-info-meta__value" }, [
        _vm._v("\n          ~12ч. (макс. 72ч.)\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "product-info-meta__label" }, [
      _c("i", { staticClass: "fas fa-paste mr-2" }),
      _c("span", [_vm._v("Формат:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "product-info-stamp__title" }, [
      _c("i", { staticClass: "far fa-check-circle mr-1" }),
      _c("span", [_vm._v("Включает отчеты из ЕГРН")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "startpage-steps__item" }, [
      _c("img", {
        staticClass: "startpage-steps__icon",
        attrs: { src: "/images/step-icons/pencil.svg" }
      }),
      _c("div", { staticClass: "startpage-steps__text" }, [
        _vm._v("\n          Укажите\n          "),
        _c("b", [_vm._v("адрес")]),
        _vm._v(" или "),
        _c("b", [_vm._v("кадастровый номер")]),
        _vm._v(" в строке поиска\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "startpage-steps__item" }, [
      _c("img", {
        staticClass: "startpage-steps__icon",
        attrs: { src: "/images/step-icons/search.svg" }
      }),
      _c("div", { staticClass: "startpage-steps__text" }, [
        _vm._v("\n          Найдите нужный объект\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }