var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-wrapper wrapper-padding_sm article" }, [
      _c("h1", [_vm._v("Отчёт о рисках")]),
      _c("p", [
        _vm._v(
          "\n      При покупке недвижимости в сделках больше всего рискует сам покупатель. Он теряет свои\n      деньги и не получает квартиру, участок, дом.\n    "
        )
      ]),
      _c("p", [
        _vm._v(
          "\n      Рассмотрим основные риски и проблемы при покупке недвижимости, а также пути их решения:\n    "
        )
      ]),
      _vm._m(0),
      _c("p", [
        _vm._v("\n      Для более полной картины вы можете\n      "),
        _c(
          "span",
          { staticClass: "btn-link", on: { click: _vm.scrollToForm } },
          [_vm._v("заказать отчет о рисках")]
        ),
        _vm._v(
          ". Проверка\n      квартиры и собственника проводится в базах Росреестр, ФССП, МВД, ИФНС, Суды и т.д. нашим\n      юридическим отделом с многолетним стажем в области недвижимости.\n    "
        )
      ]),
      _c("p", [
        _vm._v(
          "\n      На основе этой проверки создается Отчет о рисках. Данный отчет содержит полную информацию о\n      недвижимости и собственниках:\n    "
        )
      ]),
      _vm._m(1),
      _c(
        "div",
        { staticClass: "product-info-meta d-flex justify-content-between" },
        [
          _c("div", { staticClass: "product-info-meta__item" }, [
            _vm._m(2),
            _c("div", { staticClass: "product-info-meta__value" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.product && _vm.product.product_price) +
                  " руб.\n        "
              )
            ])
          ]),
          _vm._m(3),
          _c("div", { staticClass: "product-info-meta__item" }, [
            _vm._m(4),
            _c("div", { staticClass: "product-info-meta__value" }, [
              _vm._v("\n          PDF документ "),
              _c(
                "span",
                {
                  staticClass: "btn-link small",
                  on: {
                    click: function($event) {
                      return _vm.showExample("risk")
                    }
                  }
                },
                [_vm._v("Образец")]
              )
            ])
          ])
        ]
      ),
      _c("div", { staticClass: "product-info-stamp" }, [
        _vm._m(5),
        _c("div", { staticClass: "product-info-stamp__icon" }, [
          _c("ul", { staticClass: "mt-0 list-cols" }, [
            _c("li", [
              _vm._v("\n            Отчет ЕГРН о переходе прав\n            "),
              _c(
                "span",
                {
                  staticClass: "btn-link small",
                  on: {
                    click: function($event) {
                      return _vm.showExample("rightList")
                    }
                  }
                },
                [_vm._v("Образец")]
              )
            ]),
            _c("li", [
              _vm._v("\n            Отчет ЕГРН о объекте\n            "),
              _c(
                "span",
                {
                  staticClass: "btn-link small",
                  on: {
                    click: function($event) {
                      return _vm.showExample("egrn")
                    }
                  }
                },
                [_vm._v("Образец")]
              )
            ])
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "py-3 text-center" },
        [
          _c(
            "app-button",
            { attrs: { size: "xl" }, on: { click: _vm.order } },
            [_vm._v("\n        Заказать отчет\n      ")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("table", { staticClass: "table" }, [
      _c("tbody", [
        _c("tr", [
          _c("td", [
            _c("i", { staticClass: "fas fa-exclamation-circle text-danger" }),
            _vm._v(" Ограничения на рег действия")
          ]),
          _c("td", [
            _c("img", {
              staticClass: "table-img",
              attrs: {
                src: "/images/risk/logos/rosreestr2.jpg",
                alt: "Росреестр"
              }
            })
          ]),
          _c("td", [
            _vm._v(
              "\n            Не стоит тратить время - вы не сможете ее зарегистрировать даже для сделки\n            купли-продажи\n          "
            )
          ])
        ]),
        _c("tr", [
          _c("td", [
            _c("i", { staticClass: "fas fa-exclamation-circle text-danger" }),
            _vm._v(" Банкротство продавца")
          ]),
          _c("td", [
            _c("img", {
              staticClass: "table-img",
              attrs: { src: "/images/risk/logos/efrsb2.jpg", alt: "ЕФРСБ" }
            })
          ]),
          _c("td", [
            _vm._v(
              "Он не имеет право продавать квартиру. И возможно, будет просить задаток"
            )
          ])
        ]),
        _c("tr", [
          _c("td", [
            _c("i", { staticClass: "fas fa-exclamation-circle text-danger" }),
            _vm._v(" Проверка паспорта")
          ]),
          _c("td", [
            _c("img", {
              staticClass: "table-img",
              attrs: { src: "/images/risk/logos/mvd2.jpg", alt: "МВД РФ" }
            })
          ]),
          _c("td", [
            _vm._v(
              "\n            Проверка паспорта нужно прежде всего для того, чтобы выявить не используют ли продавцы\n            в сделке поддельные документы\n          "
            )
          ])
        ]),
        _c("tr", [
          _c("td", [
            _c("i", { staticClass: "fas fa-exclamation-circle text-danger" }),
            _vm._v(" Арест")
          ]),
          _c("td", [
            _c("img", {
              staticClass: "table-img",
              attrs: {
                src: "/images/risk/logos/rosreestr2.jpg",
                alt: "Росреестр"
              }
            })
          ]),
          _c("td", [
            _vm._v(
              "\n            Невозможно оформить квартиру в аресте, не стоит рассматривать\n          "
            )
          ])
        ]),
        _c("tr", [
          _c("td", [
            _c("i", { staticClass: "fas fa-exclamation-circle text-danger" }),
            _vm._v(" Долги")
          ]),
          _c("td", [
            _c("img", {
              staticClass: "table-img",
              attrs: { src: "/images/risk/logos/fssp2.jpg", alt: "ФССП" }
            })
          ]),
          _c("td", [
            _vm._v(
              "\n            Если в ФССП есть текущее исполнительное производство по собственнику или объекту -\n            скорее всего сейчас сделка не пройдет.\n          "
            )
          ])
        ]),
        _c("tr", [
          _c("td", [
            _c("i", { staticClass: "fas fa-exclamation-circle text-danger" }),
            _vm._v(" Квартира в залоге")
          ]),
          _c("td", [
            _c("img", {
              staticClass: "table-img",
              attrs: { src: "/images/risk/logos/fnp2.jpg", alt: "ФНП" }
            })
          ]),
          _c("td", [
            _vm._v(
              "\n            Залоговую квартиру может отобрать банк и вы можете потерять ваши деньги\n          "
            )
          ])
        ]),
        _c("tr", [
          _c("td", [
            _c("i", { staticClass: "fas fa-exclamation-circle text-danger" }),
            _vm._v(" Судебные разбирательства")
          ]),
          _c("td", [
            _c("img", {
              staticClass: "table-img",
              attrs: { src: "/images/risk/logos/sud-rf.svg", alt: "СудРФ" }
            })
          ]),
          _c("td", [
            _vm._v(
              "\n            Может повлиять на продолжительность процесса сделки купли-продажи.\n          "
            )
          ])
        ]),
        _c("tr", [
          _c("td", [
            _c("i", { staticClass: "fas fa-exclamation-circle text-danger" }),
            _vm._v(" История по правообладателям")
          ]),
          _c("td", [
            _c("img", {
              staticClass: "table-img",
              attrs: {
                src: "/images/risk/logos/rosreestr2.jpg",
                alt: "Росреестр"
              }
            })
          ]),
          _c("td", [
            _vm._v(
              "\n            Если объект продавали несколько раз за последние 3 года - задумайтесь.\n          "
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "list-cols" }, [
      _c("li", [_vm._v("ФИО всех собственников с 1998 года")]),
      _c("li", [_vm._v("Серию и номер свидетельства о регистрации")]),
      _c("li", [_vm._v("Даты перехода прав")]),
      _c("li", [_vm._v("Обременения (арест/залог)")]),
      _c("li", [_vm._v("Кадастровую стоимость")]),
      _c("li", [_vm._v("Задолженность собственников")]),
      _c("li", [_vm._v("Проверка действительности паспорта")]),
      _c("li", [_vm._v("Банкротство")]),
      _c("li", [_vm._v("Судебные разбирательства")]),
      _c("li", [_vm._v("В списке на снос")]),
      _c("li", [_vm._v("Массовая регистрация")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "product-info-meta__label" }, [
      _c("i", { staticClass: "far fa-credit-card mr-2" }),
      _c("span", [_vm._v("Стоимость:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "product-info-meta__item" }, [
      _c("div", { staticClass: "product-info-meta__label" }, [
        _c("i", { staticClass: "fas fa-hourglass-start mr-2" }),
        _c("span", [_vm._v("Срок исполнения:")])
      ]),
      _c("div", { staticClass: "product-info-meta__value" }, [
        _vm._v("\n          ~12ч. (макс. 72ч.)\n        ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "product-info-meta__label" }, [
      _c("i", { staticClass: "fas fa-paste mr-2" }),
      _c("span", [_vm._v("Формат:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "product-info-stamp__title" }, [
      _c("i", { staticClass: "far fa-check-circle mr-1" }),
      _c("span", [_vm._v("\n          Включает отчеты из ЕГРН\n        ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }