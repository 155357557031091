




































































































































































































































































































import Vue from 'vue';
import { EXAMPLE_TYPES } from '@/core/utils/constants/examples';
import { Validators } from '@/core/utils/validator';

import AppSearchObjectsDialog from '@/views/FreeEgrn/SearchObjectsDialog.vue';

export default Vue.extend({
  data() {
    return {
      model: {
        query: '',
      },
    };
  },

  computed: {
    product(): Product {
      return this.$store.getters.getProduct({ product_name: 'RiskAssessment' });
    },
  },

  methods: {
    showExample(exampleName: string) {
      this.$photoSwipe(EXAMPLE_TYPES[exampleName]);
    },

    onFocus() {},

    onSubmit() {
      this.$dialog.open({
        component: AppSearchObjectsDialog,
        props: {
          query: this.model.query,
          onSelect: (obj: RealtyObject) => {
            this.$dialog.close();
            this.$router.push({
              hash: '#risk',
              name: 'object-detail',
              params: { objectKey: obj.Number || obj.Address },
            });
          },
          loaderText: 'Поиск объекта',
        },
      });
    },

    scrollToForm() {
      (this.$refs.form as HTMLDivElement).scrollIntoView({ behavior: 'smooth' });
    },

    validationRules(): ValidationConfig {
      return {
        query: [ Validators.required ],
      };
    },
  },
});
