























































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';

import { EXAMPLE_TYPES } from '@/core/utils/constants/examples';

@Component
export default class RiskQuestionnaireArticle extends Vue {
  // computed
  get product(): Product {
    return this.$store.getters.getProduct({ product_name: 'RiskAssessment' });
  }

  // methods
  order() {
    this.$router.push({
      name: 'object-detail',
      hash: '#risk',
      params: {
        objectKey: this.$route.query.query as string,
      },
    });
  }

  showExample(exampleName: string) {
    this.$photoSwipe(EXAMPLE_TYPES[exampleName]);
  }

  scrollToForm() {
    (this.$refs.form as HTMLDivElement).scrollIntoView({ behavior: 'smooth' });
  }
}
