import { render, staticRenderFns } from "./RiskQuestionnaireArticle.vue?vue&type=template&id=419a13ee&scoped=true&"
import script from "./RiskQuestionnaireArticle.vue?vue&type=script&lang=ts&"
export * from "./RiskQuestionnaireArticle.vue?vue&type=script&lang=ts&"
import style0 from "./RiskQuestionnaireArticle.vue?vue&type=style&index=0&id=419a13ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "419a13ee",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/realtycloud/rcfront/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('419a13ee', component.options)
    } else {
      api.reload('419a13ee', component.options)
    }
    module.hot.accept("./RiskQuestionnaireArticle.vue?vue&type=template&id=419a13ee&scoped=true&", function () {
      api.rerender('419a13ee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/RiskPage/RiskQuestionnaireArticle.vue"
export default component.exports