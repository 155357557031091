













































































































import Vue from 'vue';
import Component from 'vue-class-component';
import Axios, { Canceler } from 'axios';

let cancel: Canceler;

@Component({
  name: 'AppSearchObjectsDialog',
  popupOptions: {
    size: 'lg',
  },
  props: {
    query: String,
    onSelect: Function,
    loaderText: {
      type: String,
      default: 'Оформляем заказ',
    },
  },
})
export default class SearchObjectsDialog extends Vue {
  // props
  query: string;
  onSelect: (obj?: RealtyObject) => void;

  // data()
  loading: boolean = true;
  error: boolean = false;
  searchResult: RealtyObject[] = [];

  // lifecycle hooks
  async created() {
    try {
      const searchResult = await this.$api.object.Search(this.query, {
        cancelToken: new Axios.CancelToken((c) => (cancel = c)),
      });
      this.searchResult = searchResult;

      if ((searchResult && searchResult.length <= 1) || !searchResult) {
        let objectInfo: any;

        if (searchResult && searchResult.length === 1) {
          objectInfo = searchResult[0];
        } else {
          objectInfo = { Address: this.query };

          if (/^(?:[0-9]+[:|/|-]?){1,}$/.test(this.query)) {
            objectInfo.Number = this.query;
          }
        }

        this.onSelect(objectInfo);
      }
    } catch (error) {
      this.error = true;
      console.error(error);
    }

    this.loading = false;
  }

  beforeDestroy() {
    if (cancel) {
      cancel();
    }
    cancel = null;
  }
}
