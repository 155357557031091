var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-dialog-close"),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "p-4" },
            [_c("app-loader", { attrs: { text: _vm.loaderText } })],
            1
          )
        : !_vm.error
        ? _c("div", { staticClass: "search-result" }, [
            _vm._m(0),
            _c("div", { staticClass: "search-result__wrap" }, [
              _c(
                "div",
                { staticClass: "search-result__table" },
                [
                  _vm._m(1),
                  _vm._l(_vm.searchResult, function(obj) {
                    return _c(
                      "div",
                      { key: obj.Number, staticClass: "search-result__row" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "search-result__cell search-result__number"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "search-result__cell-title" },
                              [
                                _vm._v(
                                  "\n              Кадастровый номер:\n            "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "search-result__cell-value" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(obj.Number) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "search-result__cell search-result__address"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "search-result__cell-title" },
                              [_vm._v("\n              Адрес:\n            ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "search-result__cell-value" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(obj.Address) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "search-result__cell search-result__area"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "search-result__cell-title" },
                              [
                                _vm._v(
                                  "\n              Площадь (кв.м.):\n            "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "search-result__cell-value" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(obj.Area) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "search-result__cell search-result__type"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "search-result__cell-title" },
                              [_vm._v("\n              Тип:\n            ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "search-result__cell-value" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(obj.ObjectType) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        ),
                        _c("div", { staticClass: "search-result__cell" }, [
                          _c("div", {
                            staticClass: "search-result__cell-title"
                          }),
                          _c(
                            "div",
                            { staticClass: "search-result__cell-value" },
                            [
                              _c(
                                "app-button",
                                {
                                  staticClass: "search-result__btn",
                                  attrs: { size: "sm" },
                                  on: {
                                    click: function($event) {
                                      return _vm.onSelect(obj)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Выбрать\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            ])
          ])
        : _c("div", { staticClass: "alert alert-danger m-0 text-center p-5" }, [
            _vm._v(
              "\n    Произошла ошибка при поиске, попробуйте ввести более точный адрес\n  "
            )
          ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "p-3" }, [
      _c("div", { staticClass: "alert alert-info m-0 text-center small" }, [
        _vm._v(
          "\n        По вашему запросу найдено несколько объектов, выберите один из них чтобы продолжить\n      "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "search-result__row search-result__header" },
      [
        _c(
          "div",
          { staticClass: "search-result__cell search-result__number" },
          [
            _c("div", { staticClass: "search-result__cell-value" }, [
              _vm._v("\n              Кадастровый номер:\n            ")
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "search-result__cell search-result__address" },
          [
            _c("div", { staticClass: "search-result__cell-value" }, [
              _vm._v("\n              Адрес:\n            ")
            ])
          ]
        ),
        _c("div", { staticClass: "search-result__cell search-result__area" }, [
          _c("div", { staticClass: "search-result__cell-value" }, [
            _vm._v("\n              Площадь (кв.м.):\n            ")
          ])
        ]),
        _c("div", { staticClass: "search-result__cell search-result__type" }, [
          _c("div", { staticClass: "search-result__cell-value" }, [
            _vm._v("\n              Тип:\n            ")
          ])
        ]),
        _c("div", { staticClass: "search-result__cell" }, [
          _c("div", { staticClass: "search-result__cell-title" }),
          _c("div", { staticClass: "search-result__cell-value" })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }